<template>
  <pui-flex
    class="plat-widget-search-table-pivot plat-widget-search-table-pivot__root"
    direction="column"
  >
    <div class="plat-widget-search-table-pivot__header">
      <span
        v-if="widget.configuration.titlePicto"
        :class="{
          'poster-dashboard-widget__icon plat-dashboard-widget__icon mdi': true,
          [widget.configuration.titlePicto]: !!widget.configuration.titlePicto,
        }"
      />
      <span
        v-if="widget.configuration.title"
        class="plat-dashboard-widget__title plat-widget-search-table-pivot__title"
      >
        {{ piivoTranslateLabel(widget.configuration.title) }}
      </span>
    </div>

    <pui-flex class="plat-widget-search-table-pivot__header-form">
      <pui-flex class="form-wrapper">
        <pui-attribute-group-panel
          v-for="group in widget.configuration.search.searchForm.attributeGroups"
          :key="group.itemId"
          :group="group"
          class="group-panel"
        >
          <pui-attribute-panel
            v-for="attribute in group.attributes"
            :key="attribute.itemId"
            :ref="getAttributeRefName(attribute.alias)"
            :attribute="attribute"
            :value="values[attribute.alias] ? values[attribute.alias].value : null"
            :loadOptionsFunction="getPossibleValues"
            :importExternalFiles="null"
            :allowExtendedDisplayMode="false"
            @valueInput="onValueInput(attribute, $event)"
            @valueChanged="onValueChanged(attribute, $event)"
          >
          </pui-attribute-panel>
        </pui-attribute-group-panel>
      </pui-flex>

      <div v-if="widget.configuration.showTotal" class="header-right">
        <router-link :to="widget.configuration.url" class="table-url-link">
          <pui-button :flat="false" class="table-url-btn" variant="primary">
            {{ widget.configuration.urlLabel | piivoTranslateLabel
            }}<i class="mdi mdi-chevron-right" />
          </pui-button>
        </router-link>
      </div>
    </pui-flex>

    <pui-common-spinner v-if="loading" position="fixed"></pui-common-spinner>
    <pui-error v-else-if="error" class="plat-widget-search-table-pivot__error">
      <i18n path="platform.dashboard.widget.table.pivot_table.error.primary"></i18n>
      <i18n path="platform.dashboard.widget.table.pivot_table.error.secondary">
        <template #btnReload>
          <span class="pui-error__btn-reload" @click="runQuery">{{
            $t('platform.dashboard.widget.table.pivot_table.error.btnReload')
          }}</span>
        </template>
      </i18n>
    </pui-error>

    <div v-else-if="!table.total" class="plat-widget-search-table-pivot__no-results">
      <i18n path="platform.dashboard.widget.table.pivot_table.no_results"></i18n>
    </div>

    <div v-else class="table-wrapper">
      <table class="table">
        <thead>
          <tr>
            <th />
            <th v-for="column of table.columns" :key="column.alias" class="center">
              <router-link
                v-if="column.url"
                :title="piivoTranslate(column)"
                :to="column.url"
                class="col-header link"
              >
                {{ column | piivoTranslate }}
              </router-link>
              <span v-else class="col-header">
                {{ column | piivoTranslate }}
              </span>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="row of table.rows" :key="row.alias">
            <td class="first-column">
              <router-link
                v-if="row.url"
                :title="piivoTranslate(row)"
                :to="row.url"
                class="row-header link"
              >
                {{ row | piivoTranslate }}
              </router-link>
              <span v-else class="row-header">
                {{ row | piivoTranslate }}
              </span>
            </td>
            <td v-for="column of table.columns" :key="column.alias" class="cellValue">
              <div
                v-if="
                  table.map[column.alias + 'x' + row.alias] &&
                  table.map[column.alias + 'x' + row.alias].url
                "
                class="cell-content"
              >
                <router-link :to="table.map[column.alias + 'x' + row.alias].url" class="cell-link">
                  <p class="center">{{ table.map[column.alias + 'x' + row.alias].count }}</p>
                </router-link>
              </div>
              <div v-else-if="table.map[column.alias + 'x' + row.alias]" class="cell-content">
                <p class="center">{{ table.map[column.alias + 'x' + row.alias].count }}</p>
              </div>
              <div v-else class="cell-content">
                <p class="center">-</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </pui-flex>
</template>

<script>
import { AttributeTypes } from 'piivo-poster-engine/src/constants';

import { coreExtension } from '../../../../core/extensionPoints';
import services from '../../../../core/services';
import {
  findAttributeInForm,
  getAttributeDefaultValue,
  iterateAttrGroup,
  linkOptionsToIdentifiers,
  updateOn,
} from '../../../../modules/common/helpers/formsHelper';
import { getPosterService } from '../../../../modules/poster/services';
import { GlobalAttributeEvents } from '../../../../modules/poster/services/globalAttributes';
import widgetMixin from '../widgetMixin';

export default {
  name: 'PlatDashboardPivotTable',
  mixins: [widgetMixin],
  data() {
    return {
      loading: true,
      loadingDefaultValues: true,
      error: false,
      values: {},
      tableDefinition: {
        total: 0,
        columns: {},
        rows: {},
        map: {},
      },
    };
  },
  computed: {
    /**
     * @returns {object} the table with computed urls
     */
    table() {
      const table = {
        columns: [],
        rows: [],
        map: {},
        total: 0,
      };

      const urlAttributeValues = {};
      Object.values(this.values).forEach((attrValue) => {
        if (attrValue.attributeAlias) {
          const value = linkOptionsToIdentifiers(attrValue.value);
          const filterAlias = this.widget.configuration.attributeFilterMapping
            ? this.widget.configuration.attributeFilterMapping[attrValue.attributeAlias]
            : '';
          urlAttributeValues[filterAlias || attrValue.attributeAlias] = value;
        }
      });

      table.columns = Object.values(this.tableDefinition.columns).map((column) => {
        return {
          ...column,
          url: this.getUrl(column, {
            ...urlAttributeValues,
            [column.attributeAlias]: column.aliasesToMatch,
          }),
        };
      });

      table.rows = Object.values(this.tableDefinition.rows).map((row) => {
        return {
          ...row,
          url: this.getUrl(row, {
            ...urlAttributeValues,
            [row.attributeAlias]: row.aliasesToMatch,
          }),
        };
      });

      Object.entries(this.tableDefinition.map).forEach(([key, cell]) => {
        table.map[key] = {
          ...cell,
          url: this.getUrl(this.widget.configuration.cell || {}, {
            ...urlAttributeValues,
            [cell.row.attributeAlias]: cell.row.aliasesToMatch,
            [cell.column.attributeAlias]: cell.column.aliasesToMatch,
          }),
        };
      });

      table.total = this.tableDefinition.total;

      return table;
    },
  },
  methods: {
    /**
     * @param {object} urlConfig - object containing url configuration
     * @param {string} urlConfig.url - the base url
     * @param {string} urlConfig.injectUrlFilters - if the current attribute values
     * should be injected as query params
     * @param {object} values - the attribute values
     * @returns {string|object} an url object
     */
    getUrl({ url, injectUrlFilters }, values) {
      if (!url) {
        return '';
      }
      if (!injectUrlFilters) {
        return url;
      }

      return {
        path: url,
        query: {
          filters: JSON.stringify(values),
        },
      };
    },
    /**
     * @param {object} attribute - the attribute whose options to retrieve
     * @param {object} parameters - search parameters
     * @param {object} parameters.context - context to send with the search
     * @returns {Promise<object[]>} options for the attribute
     */
    getPossibleValues(attribute, { context }) {
      return getPosterService('values').getPossibleValues(
        attribute,
        context,
        null,
        (bodyParameters) =>
          getPosterService('values').addAttributeValuesBodyParameters(
            bodyParameters,
            getPosterService('values').valuesObjectsToDirectMap(this.values),
            this.widget.configuration.search.searchForm.attributeGroups || []
          )
      );
    },
    /**
     * Updates attribute value
     *
     * @param {object} attribute - the attribute whose value changed
     * @param {Object} newValue - New attribute value
     */
    onValueInput(attribute, value) {
      if (this.values[attribute.id]) {
        this.values[attribute.id].value = value;
      } else {
        this.$set(this.values, attribute.alias, {
          attributeId: attribute.itemId,
          attributeAlias: attribute.alias,
          type: attribute.type,
          value: value,
        });
      }
    },
    /**
     * Updates attribute value and checks form
     *
     * @param {object} attribute - the attribute whose value changed
     * @param {Object} newValue - New attribute value
     */
    onValueChanged(attribute, value) {
      this.onValueInput(attribute, value);

      // If still loading default values :
      // - attributes may emit full value objects.
      //   This should have been triggered by loadDefaultValueObjects() and all
      //   values were initialized beforehand, so no need to update attribute options via updateOn.
      // - we cannot run the query since the values are not complete
      if (!this.loadingDefaultValues) {
        this.startUpdateOn(attribute);
        this.runQuery();
      }
    },
    /**
     * Launches the "updateOn" mechanism for the attribute
     *
     * @param {object} attribute - the attribute whose updateOn to trigger
     */
    startUpdateOn(attribute) {
      const actionCb = (cbAttr) => {
        if (cbAttr.type === AttributeTypes.LINKS) {
          this.$nextTick(() => {
            const attributePanel = this.$refs[`attribute_${cbAttr.alias}`];
            if (attributePanel != null && attributePanel[0] != null) {
              attributePanel[0].loadOptions().catch(() => null);
            }
          });
        }
      };

      updateOn(this.widget.configuration.search.searchForm, attribute, actionCb);
    },
    /**
     * @returns {string} the ref name for the attribute
     */
    getAttributeRefName(alias) {
      return `attribute_${alias}`;
    },
    /**
     * Initializes the values object with the form's
     * default values
     */
    initFormDefaultValues() {
      // Copy all extended properties. When setting a value that exists in the form,
      // remove it from this map. Remaining values will be for attributes that are not in the form.
      const initialValues = getPosterService('signages').getFormInitialValues(
        this.widget.configuration.search.searchForm.attributeGroups
      );

      // Initialize with form default values
      iterateAttrGroup(
        this.widget.configuration.search.searchForm.attributeGroups,
        async (attribute) => {
          let value = getAttributeDefaultValue(attribute);
          if (Object.hasOwnProperty.call(initialValues, attribute.alias)) {
            value = initialValues[attribute.alias];
            delete initialValues[attribute.alias];
          }
          this.$set(this.values, attribute.alias, {
            attributeId: attribute.itemId,
            attributeAlias: attribute.alias,
            type: attribute.type,
            value: value,
          });
        }
      );

      // Add the remaining user extendedProperties.
      // We cannot load full object values via the attribute panel refs
      // since the remaining keys are not in the form
      for (const key in initialValues) {
        this.$set(this.values, key, {
          attributeId: '',
          attributeAlias: key,
          type: '',
          value: initialValues[key],
        });
      }
    },
    /**
     * Loads all default value objects for links attributes. These value objects
     * are required for the query
     */
    async loadDefaultValueObjects() {
      const loadPromises = [];

      // We can only load value objects via attribute panel meaning
      // the values corresponding to attributes in our form
      iterateAttrGroup(
        this.widget.configuration.search.searchForm.attributeGroups,
        async (attribute) => {
          // If the attribute is a Link and and has no options (meaning no load has already occurred),
          // load the options to retrieve the full object values
          let attrRef = this.$refs[this.getAttributeRefName(attribute.alias)];
          attrRef = attrRef ? attrRef[0] : null;
          if (
            attrRef &&
            attrRef.value != null &&
            !attrRef.options.length &&
            attribute.type === AttributeTypes.LINKS
          ) {
            loadPromises.push(
              (async () => {
                await attrRef.loadOptions(false);

                // Grab the linkValue from the ref instead of using the event.
                // This ensures all default value objects have been set in our data
                // when the promise settles
                this.$set(this.values, attribute.alias, {
                  attributeId: attribute.itemId,
                  attributeAlias: attribute.alias,
                  type: attribute.type,
                  value: attrRef.linkValue,
                });
              })()
            );
          }
        }
      );

      await Promise.allSettled(loadPromises);
    },
    /**
     * Global value set callback
     * @param payload - event payload
     */
    onSetGlobalValue({ attribute, value }) {
      if (
        findAttributeInForm(
          this.widget.configuration.search.searchForm,
          (attr) => attr.alias === attribute.alias
        )
      ) {
        this.onValueChanged(attribute, value);
      }
    },
    /**
     * @returns {object} the values an object map of alias<>value
     */
    valuesToMap() {
      return Object.values(this.values).reduce((valuesMap, attrValue) => {
        valuesMap[attrValue.attributeAlias] = attrValue;
        return valuesMap;
      }, {});
    },
    /**
     * Runs the table query
     */
    async runQuery() {
      this.loading = true;
      this.error = false;

      try {
        const queryResult = await getPosterService('dashboards').runQuery(
          this.widget.alias,
          this.valuesToMap()
        );

        const facets = Object.keys(queryResult.facets);
        if (!facets.length) {
          throw new Error('Pivot table search returned no facets');
        }

        // Use the first facet result
        const facetResult = queryResult.facets[facets[0]];

        // Build rows, columns and cells values
        this.tableDefinition = {
          total: 0,
          columns: {},
          rows: {},
          map: {},
        };

        for (const facet of facetResult) {
          const split = facet.value.split('|');
          if (split.length !== 2) {
            continue;
          }

          // Try to parse facet as "line|column"
          let facetLines = this.widget.configuration.lines.filter(
            (line) =>
              line.aliasesToMatch.includes(split[0]) || line.itemIdsToMatch.includes(split[0])
          );
          let facetColumns;
          if (facetLines) {
            facetColumns = this.widget.configuration.columns.filter(
              (column) =>
                column.aliasesToMatch.includes(split[1]) || column.itemIdsToMatch.includes(split[1])
            );
          } else {
            // Otherwise "column|line"
            facetLines = this.widget.configuration.lines.filter(
              (line) =>
                line.aliasesToMatch.includes(split[1]) || line.itemIdsToMatch.includes(split[1])
            );
            facetColumns = this.widget.configuration.columns.filter(
              (column) =>
                column.aliasesToMatch.includes(split[0]) || column.itemIdsToMatch.includes(split[0])
            );
          }

          if (!facetLines.length || !facetColumns.length) {
            // If cannot find either line or column definition, ignore this result
            continue;
          }

          for (const facetColumn of facetColumns) {
            for (const facetLine of facetLines) {
              const mapKey = `${facetColumn.alias}x${facetLine.alias}`;

              if (!this.tableDefinition.columns[facetColumn.alias]) {
                this.$set(this.tableDefinition.columns, facetColumn.alias, facetColumn);
              }
              if (!this.tableDefinition.rows[facetLine.alias]) {
                this.$set(this.tableDefinition.rows, facetLine.alias, facetLine);
              }

              if (Object.hasOwnProperty.call(this.tableDefinition.map, mapKey)) {
                this.$set(
                  this.tableDefinition.map[mapKey],
                  'count',
                  this.tableDefinition.map[mapKey].count + facet.count
                );
              } else {
                this.$set(this.tableDefinition.map, mapKey, {
                  row: facetLine,
                  column: facetColumn,
                  count: facet.count,
                });
              }

              // Sum number of items for global total
              this.$set(this.tableDefinition, 'total', this.tableDefinition.total + facet.count);
            }
          }
        }
      } catch (err) {
        this.error = true;
        services
          .getService('alerts')
          .alertError(this.$t('platform.dashboard.widget.table.pivot_table.error.query_error'));
      }

      this.loading = false;
    },
  },
  /**
   * Component created hook
   */
  created() {
    // Initialize default values before the attribute panels are mounted
    this.initFormDefaultValues();
  },
  /**
   * Component mounted hook
   */
  async mounted() {
    try {
      this.loadingDefaultValues = true;
      // Wait for attribute panels to render
      await this.$nextTick();
      await this.loadDefaultValueObjects();
    } finally {
      this.loadingDefaultValues = false;
    }

    try {
      await this.runQuery();
    } catch {
      // Do nothing
    } finally {
      coreExtension.eventBus.on(GlobalAttributeEvents.SET_GLOBAL_VALUE, this.onSetGlobalValue);
    }
  },
  /**
   * Before destroy component hook
   */
  beforeDestroy() {
    coreExtension.eventBus.off(GlobalAttributeEvents.SET_GLOBAL_VALUE, this.onSetGlobalValue);
  },
};
</script>
